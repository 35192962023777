import React, { useContext, Suspense } from "react";
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { StylesProvider } from "@material-ui/core/styles";
import Ui from "@micado-digital/react-ui/components/Ui";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import WebProfileContext from "@micado-digital/react-ui/context/WebProfileContext";
import { theme as customTheme } from "./themes/project";

const ReactPwaAlert = loadable(() => import("@micado-digital/react-pwa-alert/ReactPwaAlert"));

const { REACT_APP_PATH } = process.env;

const theme = createTheme(customTheme);

const PwaAlert = ({ checkCookie }) => {
	const [profileData] = useContext(WebProfileContext);
	const { pwaShowInstallHint = false, pwaShowInstallHintReminder } = profileData || {};

	if (!pwaShowInstallHint) return <></>;

	return (
		<ReactPwaAlert
			appIcon={`${REACT_APP_PATH}/img/pwa/apple-icon-180.png`}
			checkCookie={checkCookie}
			reminder={pwaShowInstallHintReminder}
		/>
	);
};

const $pwaAlert = document.querySelectorAll("#mco-pwa-alert");

if ($pwaAlert) {
	$pwaAlert.forEach(element => {
		const checkCookie = element?.getAttribute("data-check-cookie") === "true";

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<WebPageData>
									<WebProfile>
										<PwaAlert checkCookie={checkCookie} />
									</WebProfile>
								</WebPageData>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}
