export const theme = {
	palette: {
		background: {
			default: "#FFFFFF"
		},
		primary: {
			light: "#3383B5",
			main: "#0064A3",
			dark: "#004672"
		},
		secondary: {
			light: "#33C9F1",
			main: "#00BCEE",
			dark: "#0083A6"
		},
		text: {
			primary: "#333333",
			secondary: "#666666"
		}
	},
	typography: {
		fontFamily: "inherit"
	}
};
